.carousel-item
{
	height: 100vh;
	min-height: 300px;
	background: no-repeat center center scroll;
	background-size: cover;

	&.tng-carousel-1
	{
		background-image: url("slide-1.jpg");
	}

	&.tng-carousel-2
	{
		background-image: url("slide-2.jpg");
	}

	&.tng-carousel-3
	{
		background-image: url("slide-3.jpg");
	}
}
